// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { getDatabase } from "firebase/database";




// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdDxMWljBfoenurGGuU4FJPDtuf9tw30k",
  authDomain: "engenheirossemfronteiros.firebaseapp.com",
  projectId: "engenheirossemfronteiros",
  storageBucket: "engenheirossemfronteiros.appspot.com",
  messagingSenderId: "792506757250",
  appId: "1:792506757250:web:48518287711468e303049b",
  measurementId: "G-K0K1S45JF9",
  databaseURL: "https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const db = getDatabase(app);

export default app;


