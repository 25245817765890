import React, {useState} from 'react';
import {Alert, Button, Carousel} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Header from "./LandingPage/Header";
import Box from "@mui/material/Box";
import '../style/LandingPageStyle.css'

import Footer from "./LandingPage/footer";
import About from './LandingPage/about';
import styled from 'styled-components'
import AppPresentation from './LandingPage/AppPresentation/appPresentation';
import HeaderCarousell from './LandingPage/HeaderCarousell';
import Colleges from './LandingPage/Colleges/colleges'
import Projects from './LandingPage/Projects/projects'
import Associations from './LandingPage/Associations/associations'
import Team from './LandingPage/Team/team';
import Nucleos from './LandingPage/Nucleos/nucleos';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%
  height: auto;
`

const Home = () => {

    const navigate = useNavigate();
    const [error,setError] = useState("");
    const signIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            navigate("/login")
        }catch (err){
            setError(err.message);
        }

    }

    return (


        <Wrapper>
            <Header/>
            {/*<HeaderCarousell/> *}*/}
            <About/>
            <AppPresentation/>
            <Colleges/>
            {/* <Projects/>    */}
            <Associations/>  
            <Team/>
            {/* <Nucleos/> */}
            <Footer/>
        </Wrapper>


    );
};

export default Home;