import React from 'react'
import { Container } from 'react-bootstrap'
import '../../style/LandingPageStyle.css'


const About = () => {
  return (
    <Container className='about-container'>
        <p className="sobre-bottom-text">Bem-vindo ao Engenheiros Sem Fronteiras, uma plataforma da Ordem dos
            Engenheiros que conecta <span className="blue-text">engenheiros</span> e <span
                className="blue-text">estudantes</span> com vontade de resolver problemas com <span
                className="orange-text">associações</span> que precisam das suas capacidades. Como <span
                className="blue-text">Problem Solver</span> podes navegar por uma lista de projetos que precisam
            da tua ajuda e fazer a tua candidatura. Como <span className="orange-text">Problem Maker</span>,
            podes publicar projetos e ser ligado a profissionais adequados ao teu problema. Liga-te a nós para
            fazeres parte de uma comunidade que tem um impacto positivo na sociedade.
        </p>
    </Container>
  )
}

export default About