import React from 'react';
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Avatar, ButtonBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BusinessIcon from '@mui/icons-material/Business';

const Topbar = () => {

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleUsers = async () => {
        try {
            navigate("/dashboard/users");
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleProjects = async () => {
        try {
            navigate("/dashboard/projects");
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleAssociations = async () => {
        try {
            navigate("/dashboard/associations");
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleDashboard = async () => {
        try {
            navigate("/dashboard");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (

        <AppBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between', backgroundColor: '#1C4E80' }}>
               

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ButtonBase sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'inherit' }} onClick={handleDashboard}>
                            <DashboardIcon />
                            <Typography variant="body2">Dashboard</Typography>
                        </ButtonBase>
                        
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ButtonBase sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'inherit' }} onClick={handleUsers}>
                            <EngineeringIcon />
                            <Typography variant="body2">Engenheiros</Typography>
                        </ButtonBase>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ButtonBase sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'inherit' }} onClick={handleAssociations}>
                                <BusinessIcon />
                                <Typography variant="body2">Associações</Typography>
                        </ButtonBase>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ButtonBase sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'inherit' }} onClick={handleProjects}>
                            <BusinessCenterIcon />
                            <Typography variant="body2">Projetos</Typography>
                        </ButtonBase>
                    </Box>

                    

                </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ButtonBase sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'inherit' }}  onClick={handleLogout}>
                            <ExitToAppIcon />
                            <Typography variant="body2">Sair</Typography>
                        </ButtonBase>
                        
                    </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;