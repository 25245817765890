import React from 'react';
import "../../style/LandingPageStyle.css"
import Box from "@mui/material/Box";
import {Container} from "react-bootstrap";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {Facebook, Instagram, Twitter} from "@mui/icons-material";

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 6,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Sobre nós
                        </Typography>
                      
                        <Typography variant="body2" color="text.secondary">
                            Ordem dos Engenheiros da Região Centro
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Carta de princípios
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Contactos
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Ordem dos Engenheiros, Lisboa, Portugal
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Email: info@example.com
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Phone: +1 234 567 8901
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Apps
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Disponivel nas lojas
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Engenheiros
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Associações
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="https://your-website.com/">
                            Engenheiros Sem Fronteiras
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;