import React from 'react';

const Regulamento = () => {
    return (
        <div>
            <p>
                <strong>1)</strong>	Os ESF funcionam através de uma plataforma web, registando:
                <p>
                    <strong>a.</strong>	Os Engenheiros ou Estudantes de Engenharia inscritos na Ordem dos Engenheiros (OE) que, potencialmente, estão interessados em realizar atividades de voluntariado, principalmente, mas não só, nas áreas da Engenharia.
                </p>   
                <p> <strong>b.</strong>	Associações Sem Fins Lucrativos, formalmente estabelecidas que apresentam desafios ou tarefas principalmente, mas não só, nas áreas da Engenharia.
                </p>
            </p>
            <p>
                <strong>2)</strong> 	As Associações registam-se na plataforma ESF fornecendo os documentos necessários que comprovem a sua situação regularizada tanto nas Finanças como na Segurança Social.
            </p>
            <p>
                <strong>3)</strong> 	As Associações registadas podem inserir desafios reais na plataforma ESF.
            </p>
            <p>
                <strong>4)</strong> 	Os desafios inseridos pelas Associações deverão ser identificados como atividades que requerem Atos de Engenharia para a sua resolução ou atividades que não requerem Atos de Engenharia, mas enquadráveis nas seguintes áreas: geriatria e solidariedade.
            </p>
            <p>
                <strong>5)</strong> 	As atividades que requerem Atos de Engenharia ficam sujeitas a uma avaliação prévia e técnica por parte de uma Comissão da Ordem dos Engenheiros da Região Centro criada para este fim específico. 
            </p>
            <p>
                <strong>6)</strong> 	Os Engenheiros ou os Estudantes de Engenharia inscritos na Ordem dos Engenheiros podem registar-se na plataforma ESF facultando o seu número de membro e inserindo o seu curriculum vitae.
            </p>
            <p>
                <strong>7)</strong> 	Os Engenheiros ou os Estudantes de Engenharia podem consultar e candidatar-se aos desafios registados.
            </p>
            <p>
                <strong>8)</strong> 	A escolha do Engenheiro ou Estudante de Engenharia para uma determinada atividade de voluntariado terá como critérios de seleção a sua competência para o desafio em causa e a sua experiência.
            </p>
            <p>
                <strong>9)</strong> 	Cada Engenheiro ou Estudante de Engenharia assume a responsabilidade total das suas ações de voluntariado, ficando a OE isenta de qualquer responsabilidade civil, criminal ou ética.
            </p>
            <p>
                <strong>10)</strong> 	Cada Engenheiro ou Estudante de Engenharia inscrito na plataforma ESF deverá fornecer:
                <p>
                    <strong>a.</strong>	Declaração de consentimento dos dados de acordo o Regulamento Geral de Protecção de Dados (RGPD) e de acordo com o Regulamento dos ESF.

                </p>
                <p>
                    <strong>b.</strong>	Compromisso de honra que se compromete a realizar estas atividades de acordo com o código de ética da OE.
                </p>
            </p>
            <p>
                <strong>11)</strong> 	As Associações assumem total responsabilidade na aceitação do Engenheiro ou Estudante de Engenharia que aceite participar nas respetivas tarefas de voluntariado.
            </p>
            <p>
                <strong>12)</strong> 	Os Engenheiros e os Estudantes de Engenharia ficarão abrangidos pelo seu seguro profissional nas atividades de voluntariado que decorram de Atos de Engenharia.
            </p>




        </div>
    )
}

export default Regulamento;