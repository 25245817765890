import React from 'react';

const CartaDePrincipios = () => {
    return (
        <div>
            <p>
                <strong>1)</strong> Engenheiros Sem Fronteiras (ESF) é um projeto humanitário sem fins lucrativos da Ordem dos Engenheiros da Região Centro que presta serviços de engenharia a título de voluntariado a pessoas e a associações sem fios lucrativos afetadas por problemas ou crises humanitárias.
            </p>
            <p>
                <strong>2)</strong>	Os ESF foram criados em 2024 na Região Centro da Ordem dos Engenheiros por profissionais de engenharia. Os ESF surgem, então, como um projeto humanitário que leva assistência nas áreas da engenharia a associações e populações carenciadas ou em sofrimento. 
            </p>
            <p>
                <strong>3)</strong>	Os ESF resultam de um projeto composto por pessoas movidas pela ideia associativa, que partilham os valores e princípios descritos nesta Carta.
            </p>
            <p>
                <strong>4)</strong>	As atividades dos ESF são, acima de tudo, de Engenharia. O trabalho é norteado pelas regras da ética universal e da deontologia profissional.
            </p>
            <p>
                <strong>5)</strong>	A ação dos ESF respeita o dever de prestar auxílio sem prejudicar qualquer pessoa individual ou grupo, e garantindo o direito à confidencialidade.
            </p>
            <p>
                <strong>6)</strong>	A sua ação é guiada pelos princípios da neutralidade, imparcialidade e independência.
            </p>
            <p>
                <strong>7)</strong>	O projeto leva assistência e cuidados a quem necessita, independentemente do local ou país onde as pessoas se encontram.
            </p>
            <p>
                <strong>8)</strong>	Os ESF são independentes: não estão subordinados a poderes políticos, militares, económicos ou religiosos e têm liberdade de ação, decidindo onde, como e quando agir com base na sua própria avaliação do contexto e das necessidades.
            </p>
            <p>
                <strong>9)</strong>	Os ESF prestam ajuda humanitária e serviço de engenharia a quem mais precisa, sem discriminar raça, religião, nacionalidade ou convicção política. A equipa de projeto define quais as populações e/ou pessoas a que dá prioridade com base, exclusivamente, na avaliação das necessidades identificadas. 
            </p>
            <p>
                <strong>10)</strong> Os ESF avaliam constantemente os projetos que desenvolvem e prestam contas à sociedade e aos doadores quanto à gestão dos recursos que angariam e ao resultado das suas ações.
            </p>

        </div>
    )
}

export default CartaDePrincipios;