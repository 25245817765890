import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Topbar from "./Topbar";
import { getDatabase, ref, orderByChild, equalTo, child, get } from "firebase/database";
import {forEach} from "react-bootstrap/ElementChildren";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Avatar, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import { click } from '@testing-library/user-event/dist/click';





const Dashboard = () => {

    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [userFalseCount , setUserFalseCount] = useState(0);

    const [associations , setAssociations] = useState([]);
    const [associationsCount, setAssociationsCount] = useState(0);
    const [associationsFalseCount , setAssociationsFalseCount] = useState(0);

    const [projects , setProjects] = useState([]);
    const [projectsCount, setProjectsCount] = useState(0);


    

   

    //UserCount
    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const apiKey = 'your-api-key';
                const databaseURL = 'https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/';
                const endpoint = `User.json?apiKey=${apiKey}`;

                const response = await fetch(`${databaseURL}/${endpoint}`);

                if (!response.ok) {
                    throw new Error(`Error fetching user list: ${response.statusText}`);
                }

                const usersData = await response.json();

                console.log(usersData)

                if (!usersData) {
                    console.error('No users found.');
                    return;
                }

                const users = Object.values(usersData);
                const usersList = Object.keys(usersData).length;

                const usersTableList = Object.entries(usersData).map(([uid, usersData])=> ({uid, ...usersData}));

                const sortedUsersList = usersTableList.map((user) => ({ ...user, RegisterDate: new Date(user.RegisterDate) }))
                    .sort((a, b) => b.RegisterDate - a.RegisterDate);

                setUsers(sortedUsersList);


                //count the number of not Enabled users
                let counter = 0;
                for (let i = 0; i < users.length; i++){
                    if(users[i].Enabled === false) {
                        console.log("User Enabled False")
                        counter++;
                    }
                }

                setUserFalseCount(counter);
                setUserCount(usersList);
                console.log(usersList);
            } catch (error) {
                console.error('Error fetching user list:', error.message);
            }
        };

       

        const fetchAssociationsCount = async () => {
            try {
                const apiKey = 'your-api-key';
                const databaseURL = 'https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/';
                const endpoint = `Association.json?apiKey=${apiKey}`;

                const response = await fetch(`${databaseURL}/${endpoint}`);

                if (!response.ok) {
                    throw new Error(`Error fetching Association list: ${response.statusText}`);
                }

                const AssociationsData = await response.json();

                console.log(AssociationsData)

                if (!AssociationsData) {
                    console.error('No Associations found.');
                    return;
                }

                const associations = Object.values(AssociationsData);
                const associationsList = Object.keys(AssociationsData).length;

                const associationsTableList = Object.entries(AssociationsData).map(([uid, associationsData])=> ({uid, ...associationsData}));

                const sortedAssociationsList = associationsTableList.map((association) => ({ ...association, RegisterDate: new Date(association.RegisterDate) }))
                    .sort((a, b) => b.RegisterDate - a.RegisterDate);

                setAssociations(sortedAssociationsList);

                //count the number of not Enabled users
                let counterAssociations = 0;
                for (let i = 0; i < associations.length; i++){
                    if(associations[i].Enabled === false) {
                        console.log("associations Enabled False")
                        counterAssociations++;
                    }
                }

                setAssociationsFalseCount(counterAssociations);
                setAssociationsCount(associationsList);
                console.log(associationsList);
            } catch (error) {
                console.error('Error fetching user list:', error.message);
            }
        };

        const fetchProjectsCount = async () => {
            try {
                const apiKey = 'your-api-key';
                const databaseURL = 'https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/';
                const endpoint = `Projects.json?apiKey=${apiKey}`;

                const response = await fetch(`${databaseURL}/${endpoint}`);

                if (!response.ok) {
                    throw new Error(`Error fetching Projects list: ${response.statusText}`);
                }

                const ProjectsData = await response.json();

                console.log(ProjectsData)

                if (!ProjectsData) {
                    console.error('No Projects found.');
                    return;
                }


                const projectsList = Object.keys(ProjectsData).length;

                const projectsTableList = Object.entries(ProjectsData).map(([uid, ProjectsData])=> ({uid, ...ProjectsData}));

                const sortedProjectsList = projectsTableList.map((association) => ({ ...association, RegisterDate: new Date(association.RegisterDate) }))
                    .sort((a, b) => b.RegisterDate - a.RegisterDate);

                setProjects(sortedProjectsList);

                setProjectsCount(projectsList);
                console.log(projectsList);
            } catch (error) {
                console.error('Error fetching projects list:', error.message);
            }
        };

        fetchProjectsCount();
        fetchAssociationsCount();
        fetchUserCount();
    }, []);




    return (

        <Box sx={{flexGrow: 1}}>

            <Topbar/>

            <Grid className={"mt-4"} sx = {{backgroundColor: "", height:""}}>



                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>

                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' , margin:"0 8px"}}>
                        <Typography variant="h6" component="div">
                            Nº Engenheiros:
                        </Typography>
                        <Typography variant="h4">{userCount}</Typography>

                        <Typography variant="h6" component="div">
                            Por ativar:
                        </Typography>
                        <Typography variant="h4">{userFalseCount}</Typography>
                    </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' , margin:"0 8px" }}>
                        <Typography variant="h6" component="div">
                            Nº Associações:
                        </Typography>
                        <Typography variant="h4">{associationsCount}</Typography>

                        <Typography variant="h6" component="div">
                            Por ativar:
                        </Typography>
                        <Typography variant="h4">{associationsFalseCount}</Typography>
                    </Paper>

                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' , margin:"0 8px" }}>
                        <Typography variant="h6" component="div">
                            Nº Projetos:
                        </Typography>
                        <Typography variant="h4">{projectsCount}</Typography>
                    </Paper>
                    </Grid>
                </Grid>




                <Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} className={"mt-4"} sx={{margin:"0 8px"}}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'center' }} >Últimos Engenheiros</Typography>
                                <TableContainer component={Paper} class={"mt-4"} display="flex">

                                    <Table sx={{minWidth: 30}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> </TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Nrº OE</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.slice(0,3).map((user,index) => (
                                                <TableRow
                                                    key={user.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell>
                                                        <Avatar alt={user.Email} src={user.ProfileURL} /></TableCell>
                                                    <TableCell >
                                                        {user.Email}
                                                    </TableCell>
                                                    <TableCell>{user.OENumber}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} className={"mt-4"} sx={{margin:"0 8px"}}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'center' }} >
                                    Últimas Associações
                                </Typography>
                                <TableContainer component={Paper} class={"mt-4"} display="flex">

                                    <Table sx={{minWidth: 30}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> </TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>NIF</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {associations.slice(0,3).map((associations,index) => (
                                                <TableRow
                                                    key={associations.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell>
                                                        <Avatar alt={associations.Email} src={associations.ProfileURL} /></TableCell>
                                                    <TableCell >
                                                        {associations.Email}
                                                    </TableCell>
                                                    <TableCell>{associations.NIF}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>

                            <Paper elevation={3} className={"mt-4"} sx={{margin:"0 8px"}}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'center' }} >
                                    Últimos Projetos
                                </Typography>
                                <TableContainer component={Paper} class={"mt-4"} display="flex">

                                    <Table sx={{minWidth: 30}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> </TableCell>
                                                <TableCell>Associação</TableCell>
                                                <TableCell>Titulo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projects.slice(0,3).map((projects,index) => (
                                                <TableRow
                                                    key={projects.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell>
                                                        <Avatar alt={projects.Association} src={projects.Logo} /></TableCell>
                                                    <TableCell >
                                                        {projects.Association}
                                                    </TableCell>
                                                    <TableCell>{projects.Title}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>

                    </Grid>

                </Box>
            </Grid>
        </Box>












    )
};

export default Dashboard;