import React , {useState} from 'react'
import {auth} from "../firebase"
import { signInWithEmailAndPassword } from "firebase/auth"
import Dashboard from "./Dashboard/Dashboard";
import {Link, useNavigate} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import {Alert} from "react-bootstrap";

import "../style/SignIn.css"




export const SignIn = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error,setError] = useState("");
    const { logIn } = useUserAuth();
    const navigate = useNavigate();

    const signIn = async (e) => {
        e.preventDefault();
        setError("");
        try {

            if(email.toLowerCase()!=="admin@esf.com"){
                setError("Não tem permissão para aceder.")
            }
            else {
                await logIn(email,password);
                navigate("/dashboard")
            }

        }catch (err){
            if(err.code == "auth/invalid-credential"){
                setError("Email/Password errados");
            }
            else if(err.code == "auth/missing-password"){
                setError("Password vazia");
            }
            else
                setError(err.message);
        }

    }

  return (


    <div className='Auth-form-container'>
        <form className={"Auth-form"} onSubmit={signIn}>



            <div className={"Auth-form-content"}>


                <h3 className={"Auth-form-title"}>Admin</h3>

                {error && <Alert variant={"danger"}>{error}</Alert>}

                <div className={"form-group mt-3"}>
                    <input type={"email"}
                           className="form-control mt-1"
                           placeholder={"Email"}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className={"form-group mt-3"}>
                    <input type={"password"}
                           className="form-control mt-1"
                           placeholder={"Password"}
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2 mt-3">
                    <button className="btn btn-primary"
                            type={"submit"}>LogIn
                    </button>
                </div>
            </div>
        </form>
    </div>
  )
}
