import React from 'react'
import LogoOE from '../../../Img/Logo/logo_oe.png'
import LogoReabilita from '../../../Img/LandingPage/logo_reabilita.png'
import './associationsStyle.css'

const Associations = () => {
  return (
    <div>
        <h2 className="projs_title">Associações parceiras</h2>

        <div className='association_logo_container'>
            
            <img src={LogoOE} alt="Logo" className='association_logo'/>
            <img src={LogoReabilita} alt="Logo" className='reabilita_logo'/>
        </div>
    </div>
  )
}

export default Associations