import React from 'react'
import './collegesStyle.css'
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';



import aeronautica from './../../../Img/LandingPage/colleges_img/colleges_aeronautica.png'
import agronomica from './../../../Img/LandingPage/colleges_img/colleges_agronomica.png'
import alimentar from './../../../Img/LandingPage/colleges_img/colleges_alimentar.png'
import ambiente from './../../../Img/LandingPage/colleges_img/colleges_ambiente.png'
import biomedica from './../../../Img/LandingPage/colleges_img/colleges_biomedica.png'
import civil from './../../../Img/LandingPage/colleges_img/colleges_civil.png'
import eletrotecnica from './../../../Img/LandingPage/colleges_img/colleges_eletrotecnica.png'
import florestal from './../../../Img/LandingPage/colleges_img/colleges_florestal.png'
import geografica from './../../../Img/LandingPage/colleges_img/colleges_geografica.png'
import geologica from './../../../Img/LandingPage/colleges_img/colleges_geologica.png'
import industrial from './../../../Img/LandingPage/colleges_img/colleges_industrial.png'
import informatica from './../../../Img/LandingPage/colleges_img/colleges_informatica.png'
import materiais from './../../../Img/LandingPage/colleges_img/colleges_materiais.png'
import mecanica from './../../../Img/LandingPage/colleges_img/colleges_mecanica.png'
import naval from './../../../Img/LandingPage/colleges_img/colleges_naval.png'
import quimica from './../../../Img/LandingPage/colleges_img/colleges_quimica.png'


import { CardGroup } from 'react-bootstrap';

const Colleges = () => {
  return (
    <div>
        
        <div className="engineering-colleges-container">
            <h2 className="eng_title">Colégios da Engenharia</h2>

            <CardGroup className='card_group'>
                <Card  className='college_card'>
                    <Card.Img variant='primary' src={aeronautica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={agronomica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={alimentar}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={ambiente}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={biomedica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={civil}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={eletrotecnica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={florestal}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={geografica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={geologica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={industrial}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={informatica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={materiais}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={mecanica}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={naval}/>
                </Card>
                <Card className='college_card'>
                    <Card.Img variant='top' src={quimica}/>
                </Card>
                
            </CardGroup>


        </div>
        
        
    </div>
  )
}
export default Colleges;
