import React, {useEffect, useState} from 'react';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Topbar from "./Topbar";
import {Avatar, TextField, Pagination, Button} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';


const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(3);
    const [selectedUser, setSelectedUser] = useState(null); // State for the selected user
    const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility


    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const apiKey = 'your-api-key';
                const databaseURL = 'https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/';
                const endpoint = `Projects.json?apiKey=${apiKey}`;

                const response = await fetch(`${databaseURL}/${endpoint}`);

                if (!response.ok) {
                    throw new Error(`Error fetching user list: ${response.statusText}`);
                }

                const projectsData = await response.json();

                console.log(projectsData)

                if (!projectsData) {
                    console.error('No users found.');
                    return;
                }

                const projectsTableList = Object.entries(projectsData).map(([uid, projectsData])=> ({uid, ...projectsData}));

                const sortedProjectsList = projectsTableList.map((association) => ({ ...association, RegisterDate: new Date(association.RegisterDate) }))
                    .sort((a, b) => b.RegisterDate - a.RegisterDate);

                setProjects(sortedProjectsList.reverse());
            } catch (error) {
                console.error('Error fetching user list:', error.message);
            }
        };

        fetchProjects();
    }, []);


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
        setCurrentPage(1);
    };

    const filteredUsers = projects.filter(project =>

        project.Title?.toLowerCase().includes(searchQuery) ||
        project.Association?.toLowerCase().includes(searchQuery) ||
        project.College?.toLowerCase().includes(searchQuery) ||
        project.Location?.toLowerCase().includes(searchQuery) ||
        project.Time?.toLowerCase().includes(searchQuery) ||
        project.Type?.toLowerCase().includes(searchQuery)

    );

    // Calculate total pages
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    // Get current page users
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };


    const handleOpenDialog = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };
    // Function to close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    return (

        <Box sx={{flexGrow: 1}}>
            <Topbar/>
            <TextField
                fullWidth
                label="Pesquisar"
                variant='outlined'
                sx={{ margin: "8px", width: 'calc(100% - 16px)'}}
                onChange={handleSearchChange}
            />
           
                <Paper elevation={3} className={"mt-4"} sx={{margin:"0 8px"}}>
                    <TableContainer component={Paper} class={"mt-4"} sx={{ mt: 4, overflow: 'hidden', borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}>

                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: '#1C4E80' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}> </TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Titulo</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Associação</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Colégio</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Localização</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Tempo</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Tipo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentUsers.map((project,index) => (
                                    <TableRow
                                        key={project.uid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                                        onClick={() => handleOpenDialog(project)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>
                                            <Avatar alt={project.Id} src={project.Logo} /></TableCell>

                                        <TableCell component="th" scope="row">
                                            {project.Title || 'N/A'}
                                        </TableCell>
                                        <TableCell>{project.Association || 'N/A'}</TableCell>
                                        <TableCell>{project.College || 'N/A'}</TableCell>
                                        <TableCell>{project.Location || 'N/A'}</TableCell>
                                        <TableCell>{project.Time || 'N/A'}</TableCell>
                                        <TableCell>{project.Type || 'N/A'}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} sx={{ '.MuiPaginationItem-root': { borderRadius: '4px' } }}/>
                    </Box>
                </Paper>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Detalhes</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        {/* Display the user's avatar */}
                        <Avatar
                            alt={selectedUser?.Id || 'N/A'}
                            src={selectedUser?.Logo || 'N/A'}
                            sx={{ width: 100, height: 100, mb: 2 }} // Adjust size as needed
                        />
                        <DialogContentText>
                            {/* Display user details */}
                            Titulo: {selectedUser?.Title}<br />
                            Associação: {selectedUser?.Association}<br />
                            Colégio: {selectedUser?.College}<br />
                            Localização: {selectedUser?.Location}<br />
                            Tempo: {selectedUser?.Time}<br />
                            Tipo: {selectedUser?.Type}<br />
                            {/* Add more details as needed */}
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Projects;