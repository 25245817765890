import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import Topbar from "./Topbar";
import Button from "@mui/material/Button";
import { Avatar, TextField, Pagination } from "@mui/material";
import { db } from "../../firebase.js";

import { getDatabase, ref, query, child, orderByChild, equalTo, get, update } from "firebase/database";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

const Users = () => {


    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(5);
    const [selectedUser, setSelectedUser] = useState(null); // State for the selected user
    const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility


    async function updateEnabledParameter(userID, newEnabledValue) {
        const db = getDatabase();

        const updates = {};
        updates['/User/' + userID + '/Enabled'] = newEnabledValue;

        try {
            await update(ref(db), updates);
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.uid === userID ? { ...user, Enabled: newEnabledValue } : user
                )
            );
            console.log('Enabled parameter updated successfully');
        } catch (error) {
            console.error('Error updating enabled parameter:', error.message);
        }

        // Update local state with the modified user data

    }

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const apiKey = 'your-api-key';
                const databaseURL = 'https://engenheirossemfronteiros-default-rtdb.europe-west1.firebasedatabase.app/';
                const endpoint = `User.json?apiKey=${apiKey}`;

                const response = await fetch(`${databaseURL}/${endpoint}`);

                if (!response.ok) {
                    throw new Error(`Error fetching user list: ${response.statusText}`);
                }

                const usersData = await response.json();

                console.log(usersData)

                if (!usersData) {
                    console.error('No users found.');
                    return;
                }
                const usersList = Object.entries(usersData).map(([uid, usersData]) => ({ uid, ...usersData }));

                const sortedUsersList = usersList.sort((a, b) => (a.Enabled === b.Enabled ? 0 : a.Enabled ? 1 : -1));

                setUsers(sortedUsersList);
            } catch (error) {
                console.error('Error fetching user list:', error.message);
            }
        };

        fetchUsers();
    }, []);





    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
        setCurrentPage(1);
    };

    const filteredUsers = users.filter(user =>
        user.Email?.toLowerCase().includes(searchQuery) ||
        user.Company?.toLowerCase().includes(searchQuery) ||
        user.Course?.toLowerCase().includes(searchQuery) ||
        user.Subcollege?.toLowerCase().includes(searchQuery) ||
        user.OENumber?.toLowerCase().includes(searchQuery)

    );

    // Calculate total pages
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    // Get current page users
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };


    const handleOpenDialog = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };
    // Function to close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (

        <Box sx={{ flexGrow: 1 }}>

            <Topbar />
            <TextField
                fullWidth
                label="Pesquisar"
                variant='outlined'
                sx={{ margin: "8px", width: 'calc(100% - 16px)'}}
                onChange={handleSearchChange}
            />
            <Paper elevation={1} className={"mt-4"} sx={{ margin: "0 8px" }}>

                <TableContainer component={Paper} class={"mt-4"} sx={{ mt: 4, overflow: 'hidden', borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#1C4E80' }}> 
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}> </TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Empresa</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Curso</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Colégio</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Nrº OE</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentUsers.map((user, index) => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                                    onClick={() => handleOpenDialog(user)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Avatar
                                            alt={user.Email || 'N/A'}
                                            src={user.ProfileURL}
                                        /></TableCell>
                                    <TableCell >
                                        {user.Email || 'N/A'}
                                    </TableCell>
                                    <TableCell>{user.Company || 'N/A'}</TableCell>
                                    <TableCell>{user.Course || 'N/A'}</TableCell>
                                    <TableCell>{user.Subcollege || 'N/A'}</TableCell>
                                    <TableCell>{user.OENumber || 'N/A'}</TableCell>
                                    <TableCell align={"right"}>
                                        <Button variant={user.Enabled ? 'contained' : 'outlined'}
                                            color={user.Enabled ? 'success' : "error"}
                                            onClick={(event) => (
                                                event.stopPropagation(),
                                                updateEnabledParameter(user.uid, !user.Enabled))}>
                                            {
                                                user.Enabled ? "Ativo" : "Ativar"
                                            }
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} sx={{ '.MuiPaginationItem-root': { borderRadius: '4px' } }}/>
                </Box>
            </Paper>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Detalhes</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        {/* Display the user's avatar */}
                        <Avatar
                            alt={selectedUser?.Email || 'N/A'}
                            src={selectedUser?.ProfileURL}
                            sx={{ width: 100, height: 100, mb: 2 }} // Adjust size as needed
                        />
                        <DialogContentText>
                            {/* Display user details */}
                            Email: {selectedUser?.Email}<br />
                            Company: {selectedUser?.Company}<br />
                            Course: {selectedUser?.Course}<br />
                            Subcollege: {selectedUser?.Subcollege}<br />
                            OE Number: {selectedUser?.OENumber}<br />
                            {/* Add more details as needed */}
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>




        </Box>

    );
};

export default Users;