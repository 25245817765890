import React from 'react';
import './team.css';
import avatar from '../../../Img/LandingPage/avatar.png';
import Moises from '../../../Img/LandingPage/TeamMembers/Moises.jpeg';
import Joao from '../../../Img/LandingPage/TeamMembers/JoaoMonteiro.jpg'

const Team = () => {
    const teamMembers = [
        { name: 'António Dias', picture: Moises, jobTitle: 'Ph.D Student' },
        { name: 'Isabel Lança', picture: avatar, jobTitle: 'Job Title' },
        { name: 'Isabel Quintaneiro', picture: avatar, jobTitle: 'Job Title' },
        { name: 'João Monteiro', picture: Joao, jobTitle: 'Job Title' },
        { name: 'Jorge Sá Silva', picture: 'https://eden.dei.uc.pt/~sasilva/SaSilva_files/JorgeSaSilva.jpg', jobTitle: 'Job Title' },
        { name: 'Paula Mata', picture: avatar, jobTitle: 'Job Title' },
        { name: 'Pedro Carreira', picture: avatar, jobTitle: 'Job Title' },
        { name: 'Susana Ferreira', picture: avatar, jobTitle: 'Job Title' },
    ];

    return (
        <div>
            <h2 className="titled">Equipa</h2>
            <div className="team-carousel">
                

                {teamMembers.map((member, index) => (
                    <div key={index} className="team-member">
                        <img src={member.picture} alt={member.name} className="avatar" />
                        <h3 className="name">{member.name}</h3>
                   
                    </div>
                ))}
            </div>      
        </div>
        
    )
}

export default Team;

